var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "Modal",
        {
          attrs: {
            closable: true,
            title: (_vm.mode == "add" ? "添加" : "编辑") + "合同主体",
            size: "normal",
            visible: _vm.visible,
          },
          on: { cancel: _vm.onClose, ok: _vm.onSubmit },
        },
        [
          _c(
            "a-form-model",
            {
              ref: "ruleForm",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-col": { span: 8 },
                "wrapper-col": { span: 16 },
              },
            },
            [
              _c(
                "a-form-model-item",
                { attrs: { label: "企业名称", prop: "companyName" } },
                [
                  _c("a-input", {
                    attrs: { "max-length": 20, placeholder: "最多20个字符" },
                    model: {
                      value: _vm.form.companyName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "companyName", $$v)
                      },
                      expression: "form.companyName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "法人代表", prop: "corporate" } },
                [
                  _c("a-input", {
                    attrs: { "max-length": 20, placeholder: "最多20个字符" },
                    model: {
                      value: _vm.form.corporate,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "corporate", $$v)
                      },
                      expression: "form.corporate",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "统一社会信用代码", prop: "companyCode" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入正确代码" },
                    model: {
                      value: _vm.form.companyCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "companyCode", $$v)
                      },
                      expression: "form.companyCode",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "注册地", prop: "address" } },
                [
                  _c("CbSelectArea", {
                    ref: "selectArea",
                    attrs: { "area-show": true },
                    on: { change: _vm.onChange },
                    model: {
                      value: _vm.form.address,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "address", $$v)
                      },
                      expression: "form.address",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }